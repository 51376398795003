import React, { useMemo, useState, useEffect } from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const LegalCookieSwitch = ({ data, index }) => {

  // console.log('[LegalCookieSwitch.js] data', data)

  const [cookieAnaliticsState, setCookieAnaliticsState] = useState(false)

  const title = useMemo(() => (
    get(data, 'primary.cookie_title[0].text')
  ), [data])
  
  const description = useMemo(() => (
    get(data, 'primary.description')
  ), [data])

  const cookie = useMemo(() => (
    get(data, 'primary.cookie')
  ), [data])

  const toggleAnacitics = () => {
    // window.localStorage.cookieAnalitics = "true"

    if(cookieAnaliticsState === false) {
      setCookieAnaliticsState(true)
      window.localStorage.cookieAnalitics = "true"
    } else {
      setCookieAnaliticsState(false)
      window.localStorage.cookieAnalitics = "false"
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem("cookieAnalitics") === "true") {
      setCookieAnaliticsState(true)
    }
  })

  return(
    <div 
      key={index}
      className="legal_pages__cookie font-book p-medium">
      
      <div className="legal_pages__cookie__switch switch-input">
        <h3>{title}</h3>
        {cookie === 'Necessary' ? 
          <label className="switch">
            <input type="checkbox" checked={true} readOnly />
            <span className="slider round"></span>
          </label>
        : cookie === 'Analytics' ?
        <label className="switch">
          <input type="checkbox" checked={cookieAnaliticsState} onChange={toggleAnacitics} />
          <span className="slider round"></span>
        </label>
        : null }
      </div>

      <RichText
        render={description}
        linkResolver={linkResolver}
      />
    </div>
  )  
}

export default LegalCookieSwitch
