import React, { useMemo } from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const LegalText = ({ data, index, termsCurrentLocation }) => {

  // console.log('[LegalText.js] data', data)

  const text = useMemo(() => (
    get(data, 'primary.text')
  ), [data])

  const target_location = useMemo(() => (
    get(data, 'primary.target_location')
  ), [data])

  if(termsCurrentLocation !== target_location && termsCurrentLocation !== '')
    return null

  return(
    <div 
      key={index}
      className="legal_pages__text font-book">

      <RichText
        render={text}
        linkResolver={linkResolver}
      />
    </div>
  )  
}

export default LegalText
