import React, { useMemo, useState } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LegalText from "../components/slices/LegalText"
import { linkResolver } from "../utils/linkResolver"
import LegalCookieSwitch from "../components/slices/LegalCookieSwitch"
import { Strings } from "../utils/strings"

// Display the title, date, and content of the Post
const LegalBody = ({ page, navigation }) => {
  // console.log('[legal.js] page', page)

  const title = useMemo(() => get(page, "title[0].text"), [page])

  const uid = useMemo(() => get(page, "_meta.uid"), [page])

  const allStrings = Strings(page)

  // const content = useMemo(() => (
  //   get(page, 'content')
  // ), [page])

  const [termsCurrentLocation, setTermsCurrentLocation] = useState("")
  let locations = []

  const renderContainer = (container, index) => {
    if (container && container.type === "text") {
      // console.log('renderContainer ', container)
      const target_location = get(container, "primary.target_location")
      if (locations.find(el => el === target_location) === undefined) {
        locations.push(target_location)
      }
      return (
        <LegalText
          key={index}
          data={container}
          index={index}
          termsCurrentLocation={termsCurrentLocation}
        />
      )
    }

    if (container && container.type === "cookie_switcher") {
      return <LegalCookieSwitch key={index} data={container} index={index} />
    }

    return []
  }

  // const addLocation = (location) => {
  //   console.log('location', location)
  //   console.log('find', allLocations.find(location))

  //   if(allLocations.find(location) === 0) {
  //     setAllLocations(...allLocations, location)
  //   }

  // }

  // if(allLocations) {
  //   setTermsCurrentLocation(allLocations[0])
  // }

  // console.log('allLocations', allLocations.length )

  const renderLink = (link, index) => {
    // console.log('renderLink link', link)

    const link_name = get(link, "primary.legal_page.title[0].text")
    const link_meta = get(link, "primary.legal_page._meta")
    const linkUrl = linkResolver(link_meta)

    return (
      <li key={index}>
        <a href={linkUrl} className={link_meta?.uid === uid ? "active" : ""}>
          {link_name}
        </a>
      </li>
    )
  }

  // Toggle Mobile Button

  const [mobileButtonState, setMobileButtonState] = useState("")
  const toggleMobileButton = () => {
    // setSortButtonState(!sortButtonState)
    if (mobileButtonState === "") {
      setMobileButtonState(" open")
    } else {
      setMobileButtonState("")
    }
  }

  const renderMobileLink = (link, index) => {
    // console.log('renderLink link', link)

    const link_name = get(link, "primary.legal_page.title[0].text")
    const link_meta = get(link, "primary.legal_page._meta")
    const linkUrl = linkResolver(link_meta)

    return (
      <li key={index} className={link_meta?.uid === uid ? "active" : ""}>
        <a href={linkUrl}>
          <span className="btn-dropdown__items__square"></span>
          {link_name}
        </a>
      </li>
    )
  }

  const [locationButtonState, setLocationButtonState] = useState("")
  const togglesetLocationButton = () => {
    // setSortButtonState(!sortButtonState)
    if (locationButtonState === "") {
      setLocationButtonState(" open")
    } else {
      setLocationButtonState("")
    }
  }

  const renderLocationLink = (location, index) => {
    if (index === 0 && termsCurrentLocation === "") {
      setTermsCurrentLocation(location)
    }

    return (
      <li
        key={index}
        className={location === termsCurrentLocation ? "active" : ""}
        tabIndex="0"
        onKeyDown={() => setTermsCurrentLocation(location)}
        onClick={() => {
          setTermsCurrentLocation(location)
          setLocationButtonState("")
        }}
        role="button"
      >
        <span className="btn-dropdown__items__square"></span>
        {location}
      </li>
    )
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        <div className="row">
          <div className="col col6 legal_pages__left_col">
            <ul className="legal_pages__nav">
              {navigation &&
                navigation?.body1?.map((link, index) =>
                  renderLink(link, index)
                )}
            </ul>

            <div
              className={
                "legal_pages__nav_button btn-dropdown" + mobileButtonState
              }
            >
              <div
                tabIndex="0"
                className="btn-dropdown__name"
                onKeyDown={() => toggleMobileButton()}
                onClick={() => toggleMobileButton()}
                role="button"
              >
                {title}
              </div>
              <ul className="btn-dropdown__items">
                {navigation &&
                  navigation?.body1?.map((link, index) =>
                    renderMobileLink(link, index)
                  )}
              </ul>
            </div>

            <hr />
          </div>
          <div className="col col6 legal_pages__right_col">
            <h1 className="h3 legal_pages__title">{title}</h1>

            {page &&
              page?.body?.map((container, index) =>
                renderContainer(container, index)
              )}

            {locations.length > 1 ? (
              <div class="legal_pages__location_button">
                <h4 className="p-large legal_pages__location_button__title">
                  {allStrings?.legal_current_location ?? "Current location"}:
                </h4>
                <div
                  className={
                    "legal_pages__location_button__btn btn-dropdown" +
                    locationButtonState
                  }
                >
                  <div
                    tabIndex="0"
                    className="btn-dropdown__name"
                    onKeyDown={() => togglesetLocationButton()}
                    onClick={() => togglesetLocationButton()}
                    role="button"
                  >
                    {termsCurrentLocation}
                  </div>
                  <ul className="btn-dropdown__items">
                    {locations &&
                      locations.map((location, index) =>
                        renderLocationLink(location, index)
                      )}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allLegals.edges[0]
  const navigation = props.data.prismic.allNavigations.edges[0]

  if (!doc) return null

  const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(() => get(doc, "node.seo_image.url"), [doc])

  return (
    <Layout pathname={props.path} meta={meta}>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <LegalBody page={doc.node} navigation={navigation.node} />
    </Layout>
  )
}

export const query = graphql`
  query allLegal($lang: String, $uid: String) {
    prismic {
      allLegals(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            seo_title
            seo_meta
            seo_image
            title
            body {
              ... on PRISMIC_LegalBodyText {
                type
                label
                primary {
                  text
                  target_location
                }
              }
              ... on PRISMIC_LegalBodyCookie_switcher {
                type
                label
                primary {
                  description
                  cookie_title
                  cookie
                }
              }
            }
          }
        }
      }
      allNavigations(uid: "navigation", lang: $lang) {
        edges {
          node {
            body1 {
              ... on PRISMIC_NavigationBody1Link {
                type
                label
                primary {
                  legal_page {
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Page_template {
                      title
                    }
                    ... on PRISMIC_Legal {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
